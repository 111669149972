import React from "react";

function Contact() {
    return (
        <section id="contact" className="content-section">
            <div className="content entire-paragraph">
                <h2>Contact Us</h2>
                <h3>Have questions or want to get involved? Reach out to us!</h3>
                <h4>Email: info@computersforstudents.org</h4>
            </div>
        </section>
    );
}

export default Contact;
