import * as React from "react";
import {Link} from "react-router-dom";

function Donation() {
    return (
        <section id="donation" className="content-section">
            <div className="content entire-paragraph">
                <h2>Donation</h2>
                <h3>Interested in contributing to our cause? Join our mission and donate today!</h3>
                <h4>Donation of laptops and desktops are received at the <Link to="/contact">drop off location</Link>
                </h4>
                <p className="text-center">
                    <b>Before donating your devices, please read these detailed instructions on how to <a
                        href="https://www.nytimes.com/wirecutter/guides/how-to-securely-wipe-your-computer-devices/">
                        wipe
                    </a> your data off of your computer and how to <a
                        href="https://www.nytimes.com/wirecutter/guides/how-to-back-up-your-computer/">
                        back up
                    </a> your data are listed in these two guides.
                    </b>
                </p>
                <p className="text-center">
                    <b>Computers for Students consider our donors' privacy seriously. Privacy is our utmost priority</b>
                </p>
                <p>
                    In our commitment to fostering trust with our donors, we will take video or photo documentation of
                    each laptop/desktop, showcasing the complete erasure of data. This not only assures donors of their
                    privacy but also provides a transparent and accountable process, reinforcing our dedication to the
                    security and confidentiality of their information. These evidences can be accessible through the
                    personal Computers for Students account, accessible via logging in or signing up with an
                    account.</p>
            </div>
        </section>
    );
}

export default Donation;
