import React from "react";

const footerStyle = {
    backgroundColor: "#E7FEE3",
    color: "black",
    textAlign: "center",
    padding: "10px",
    position: "fixed",
    bottom: 0,
    width: "100%"
};

function Footer() {
    return (
        <footer style={footerStyle}>
            <h3>&copy; 2024, Computers for Students. All rights reserved.</h3>
        </footer>
    );
}

export default Footer;
