import {TextField} from "@aws-amplify/ui-react";
import breaks from "@bytemd/plugin-breaks";
import frontmatter from "@bytemd/plugin-frontmatter";
import gemoji from "@bytemd/plugin-gemoji";
import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import math from "@bytemd/plugin-math";
import mediumZoom from "@bytemd/plugin-medium-zoom";
import mermaid from "@bytemd/plugin-mermaid";
import {Editor} from "@bytemd/react";
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {Amplify} from "aws-amplify";
import {generateClient} from "aws-amplify/api";
import {fetchAuthSession} from "aws-amplify/auth";
import {uploadData} from "aws-amplify/storage";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import {useLocation, useNavigate} from "react-router-dom";
import {getBlogPost} from "../graphql/queries";
import {createBlogPost, updateBlogPost} from "../graphql/mutations";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
const plugins = [
    breaks(),
    frontmatter(),
    gemoji(),
    gfm(),
    highlight(),
    math(),
    mediumZoom(),
    mermaid()
];

function BlogForm() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [formData, setFormData] = useState({
        title: "",
        short_description: "",
        image: null,
        long_description: "",
        date: dayjs().unix()
    });
    const [editImage, setEditImage] = useState(false);

    function handleInputChange(field) {
        return function (event) {
            setFormData({
                ...formData,
                [field]: event.target.value
            });
        };
    }

    async function uploadImage(image) {
        return uploadData({
            key: dayjs().unix() + "_" + image.name,
            data: image
        }).result;
    }

    async function uploadImages(images) {
        const promises = images.map(image => {
            return uploadImage(image);
        });
        const resp = await Promise.all(promises);
        const s3 = Amplify.getConfig().Storage.S3;
        return resp.map(item => {
            return {url: "https://" + s3.bucket + ".s3." + s3.region + ".amazonaws.com/public/" + item.key};
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const client = generateClient();
        if (state?.id) {
            const {createdAt, updatedAt, __typename, ...updateFormData} = formData;
            await client.graphql({
                query: updateBlogPost,
                variables: {
                    input: {
                        ...updateFormData,
                        id: state.id
                    }
                }
            });
            navigate("/blog");
            return;
        }
        const result = await uploadImage(formData.image);
        await client.graphql({
            query: createBlogPost,
            variables: {
                input: {
                    ...formData,
                    date: dayjs.unix(),
                    image: result.key
                }
            }
        });
        navigate("/blog");
    }

    useEffect(() => {
        async function currentSession() {
            const {accessToken} = (await fetchAuthSession()).tokens ?? {};
            if (!accessToken?.payload["cognito:groups"].includes("cfsAdmin")) {
                navigate("/login");
                return;
            }
            if (!state?.id) {
                return;
            }
            const client = generateClient();
            const blogPost = await client.graphql({
                query: getBlogPost,
                variables: {id: state.id},
                authMode: "iam"
            });
            setFormData(blogPost.data.getBlogPost);
        }

        void currentSession();
    }, [navigate, state]);
    return <section id="home">
        <div className="content entire-paragraph">
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField required label="Title" variant="outlined" value={formData.title}
                                   onChange={handleInputChange("title")}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField required label="Short Description" variant="outlined"
                                   value={formData.short_description}
                                   onChange={handleInputChange("short_description")}/>
                    </Grid>
                    <Grid item xs={6}>
                        {state?.id &&
                            <FormControlLabel control={<Checkbox />} checked={editImage} onChange={(event) => setEditImage(event.target.checked)} label="Edit Image?" />
                        }
                        {(!state?.id || editImage) &&
                            <>
                                <FileUploader required handleChange={file => setFormData({...formData, image: file})}
                                              name="file" types={fileTypes}/>
                                <p>{formData.image && typeof formData.image !== 'string' ? `File name: ${formData.image.name}` : ""}</p>
                            </>

                        }
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onChange={(newValue) => setFormData({...formData, date: newValue.unix()})}
                                        label="Date" variant="outlined" value={dayjs.unix(formData.date)}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Editor plugins={plugins} value={formData.long_description} uploadImages={uploadImages}
                                onChange={content => setFormData({...formData, long_description: content})}
                                mode="split"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">Submit</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    </section>;
}

export default BlogForm;
