import {CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {generateClient} from "aws-amplify/api";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {listGuidePosts} from "../graphql/queries";

function Guide() {
    const navigate = useNavigate();
    const [guidesCategory, setGuidesCategory] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const client = generateClient();
            const resp = await client.graphql({
                query: listGuidePosts,
                variables: {},
                authMode: "iam"
            });
            setGuidesCategory(Array.from(new Set(resp.data.listGuidePosts.items.map((item) => item.category))));
        }
        void fetchData();
    }, [navigate]);
    return (
        <section id="guide" className="content-section">
            <div className="content entire-paragraph">
                <h1 style={{textAlign: "center"}}>Guides</h1>
                <Grid container style={{marginTop: 25}}>
                    {
                        guidesCategory?.map((category) => (
                            <Grid item xs={12} lg={6} key={category} style={{marginBottom: 50}}>
                                <Card className="card-post" sx={{maxWidth: 400}} orientation="vertical"
                                      size="md" variant="soft" style={{margin: "0 auto"}} onClick={() =>
                                    navigate("/guide_category", {state: {category: category}})}>
                                    <CardHeader className="card-post" title={category} titleTypographyProps={{align: 'center'}}/>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </section>
    );
}

export default Guide;
