import {Authenticator} from "@aws-amplify/ui-react";
import {Amplify} from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import awsmobile from "./aws-exports";

Amplify.configure(awsmobile);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Authenticator.Provider>
                <App/>
            </Authenticator.Provider>
        </BrowserRouter>
    </React.StrictMode>
);
