/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGuidePost = /* GraphQL */ `
  query GetGuidePost($id: ID!) {
    getGuidePost(id: $id) {
      id
      category
      position
      title
      date
      short_description
      long_description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGuidePosts = /* GraphQL */ `
  query ListGuidePosts(
    $filter: ModelGuidePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuidePosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        position
        title
        date
        short_description
        long_description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBlogPost = /* GraphQL */ `
  query GetBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      title
      date
      short_description
      image
      long_description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBlogPosts = /* GraphQL */ `
  query ListBlogPosts(
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        date
        short_description
        image
        long_description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDonationRecord = /* GraphQL */ `
  query GetDonationRecord($id: ID!) {
    getDonationRecord(id: $id) {
      id
      name
      email
      device_model_name
      external_serial_number
      internal_serial_number
      device_condition
      device_status
      device_use
      comments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDonationRecords = /* GraphQL */ `
  query ListDonationRecords(
    $filter: ModelDonationRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDonationRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        device_model_name
        external_serial_number
        internal_serial_number
        device_condition
        device_status
        device_use
        comments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
