import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import "katex/dist/katex.css";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {generateClient} from "aws-amplify/api";
import {fetchAuthSession} from "aws-amplify/auth";

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "bytemd/dist/index.css";
import {listDonationRecords} from "../graphql/queries";
import "highlight.js/styles/default.css";

function Login() {
    const {user, signOut} = useAuthenticator((context) => [context.user]);
    const [donations, setDonations] = useState([]);
    const [admin, setAdmin] = useState(false);
    const navigate = useNavigate();

    async function currentSession() {
        const {accessToken} = (await fetchAuthSession()).tokens ?? {};
        setAdmin(accessToken?.payload["cognito:groups"].includes("cfsAdmin"));
    }

    useEffect(() => {
        async function fetchData() {
            if (!user) {
                return;
            }
            if (!user.signInDetails?.loginId) {
                await signOut();
                navigate("/");
                return;
            }
            const client = generateClient();
            const resp = await client.graphql({
                query: listDonationRecords,
                variables: {
                    filter: {
                        email: {eq: user?.signInDetails?.loginId}
                    }
                }
            });
            setDonations(resp.data.listDonationRecords.items);
        }

        void fetchData();
        void currentSession();
    }, [signOut, user, navigate]);

    return (
        <section id="home" className="content-section">
            <div className="content entire-paragraph">
                <Authenticator>
                    {({signOut}) => (
                        <>
                            <h2>Donation Records</h2>
                            <h3>Welcome {donations.at(0)?.name}!</h3>
                            <h4>You are signed in.</h4>
                            <TableContainer component={Paper} className="table">
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Device Model Name</TableCell>
                                            <TableCell>Serial Number</TableCell>
                                            <TableCell>Internal Serial Number</TableCell>
                                            <TableCell>Device Condition</TableCell>
                                            <TableCell>Device Status</TableCell>
                                            <TableCell>Device Use</TableCell>
                                            <TableCell>Comments(s)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {donations.map((row) => (
                                            <TableRow key={row.id}
                                                      sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                                <TableCell>{row.device_model_name}</TableCell>
                                                <TableCell>{row.external_serial_number}</TableCell>
                                                <TableCell>{row.internal_serial_number}</TableCell>
                                                <TableCell>{row.device_condition}</TableCell>
                                                <TableCell>{row.device_status}</TableCell>
                                                <TableCell>{row.device_use}</TableCell>
                                                <TableCell>{row.comments}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <p className="text-center">Please reference your devices using the internal serial
                                                       number.</p>
                            <div className="center-items">
                                <Stack spacing={2}>
                                    {
                                        admin &&
                                        <>
                                            <Button variant="outlined" onClick={() => navigate("/form")}>
                                                Add Blog Post
                                            </Button>
                                            <Button variant="outlined" onClick={() => navigate("/guideForm")}>
                                                Add Guide
                                            </Button>
                                        </>
                                    }
                                    <Button onClick={signOut} variant="contained" color="primary">
                                        Sign Out
                                    </Button>
                                </Stack>
                            </div>
                        </>
                    )}
                </Authenticator>
            </div>
        </section>
    );
}

export default Login;