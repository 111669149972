import "./pages/Common.css";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {createTheme, Grid, Menu, MenuItem, ThemeProvider} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React, {useRef, useState} from "react";

import {Route, Routes, useNavigate} from "react-router-dom";
import GuideCategory from "./pages/GuideCategory";
import GuideForm from "./pages/GuideForm";
import GuideDetail from "./pages/GuideDetail";
import Guide from "./pages/Guide";
import Footer from "./Footer";
import About from "./pages/About";
import Achievements from "./pages/Achievements";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import BlogForm from "./pages/BlogForm";
import Contact from "./pages/Contact";
import Donation from "./pages/Donation";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Programs from "./pages/Programs";

const theme = createTheme({
    palette: {
        primary: {
            main: "#000000"
        }
    }
});

function App() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);

    function handleInfoClick(event) {
        setAnchorEl(event.currentTarget);
        setInfoOpen(true);
    }

    function handleClose() {
        setAnchorEl(null);
        setInfoOpen(false);
    }

    const ref = useRef(null);

    return (
        <div ref={ref}>
            <ThemeProvider theme={theme}>
                <Grid container spacing={2} alignItems="center" className="grid header-color">
                    <Grid item xs={4}>
                        <header>
                            <img src="/header.png" width={90} height={70} alt="" style={{cursor: "pointer"}}
                                 onClick={() => navigate("")}/>
                        </header>
                    </Grid>
                    <Grid container item xs={8} alignItems="flex-end" justifyContent="flex-end">
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <div>
                                <Button variant="text" aria-controls={infoOpen ? "basic-menu" : undefined}
                                        aria-haspopup="true" aria-expanded={infoOpen ? "true" : undefined}
                                        onClick={handleInfoClick} endIcon={<KeyboardArrowDownIcon/>}
                                        className="button-text-color">
                                    Info
                                </Button>
                                <Menu id="basic-menu" anchorEl={anchorEl} open={infoOpen} onClose={handleClose}
                                      MenuListProps={{"aria-labelledby": "basic-button"}}>
                                    <MenuItem onClick={() => {
                                        navigate("/about");
                                        handleClose();
                                    }}>
                                        Who We Are
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate("/programs");
                                        handleClose();
                                    }}>
                                        What We Do
                                    </MenuItem>
                                </Menu>
                            </div>
                            <Button className="button-text-color" variant="text" onClick={() => navigate("/login")}>
                                Login
                            </Button>
                            <Button className="button-text-color" variant="text"
                                    onClick={() => navigate("/contact")}>
                                Contact
                            </Button>
                            <Button className="button-text-color" variant="text" onClick={() => navigate("/blog")}>
                                Blog
                            </Button>
                            <Button className="button-text-color" variant="text" onClick={() => navigate("/guide")}>
                                Guide
                            </Button>
                            <Button className="button-text-color" variant="text"
                                    onClick={() => navigate("/donation")}>
                                Donation
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <div>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/contact" element={<Contact/>}/>
                    <Route exact path="/donation" element={<Donation/>}/>
                    <Route exact path="/programs" element={<Programs/>}/>
                    <Route exact path="/blog" element={<Blog/>}/>
                    <Route exact path="/achievements" element={<Achievements/>}/>
                    <Route exact path="/login" element={<Login/>}/>
                    <Route exact path="/form" element={<BlogForm/>}/>
                    <Route exact path="/blog_detail" element={<BlogDetail/>}/>
                    <Route exact path="/guide_category" element={<GuideCategory/>}/>
                    <Route exact path="/guide" element={<Guide/>}/>
                    <Route exact path="/guideForm" element={<GuideForm/>}/>
                    <Route exact path="/guide_detail" element={<GuideDetail/>}/>
                </Routes>
            </div>
            <div style={{height: "100px"}}></div>
            <Footer/>
        </div>
    );
}

export default App;
