import React from "react";

function Home() {
    return (
        <section id="home">
            <div className="content entire-paragraph">
                <h2>Welcome to the Computers for Students Homepage</h2>
                <h3>Empowering a new generation of individuals prepared for the digital world.</h3>
            </div>
        </section>
    );
}

export default Home;
