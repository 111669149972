import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditIcon from "@mui/icons-material/Edit"
import {CardHeader} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {generateClient} from "aws-amplify/api";
import {fetchAuthSession} from "aws-amplify/auth";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {listGuidePosts} from "../graphql/queries";

function GuideCategory() {
    const navigate = useNavigate();
    const [guides, setGuides] = useState([]);
    const {state} = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        async function currentSession() {
            const {accessToken} = (await fetchAuthSession()).tokens ?? {};
            setIsAdmin(accessToken?.payload["cognito:groups"].includes("cfsAdmin"));
        }
        async function fetchData() {
            if (!state?.category) {
                navigate("/guide");
                return;
            }
            const client = generateClient();
            const resp = await client.graphql({
                query: listGuidePosts,
                variables: {filter: {category: {eq: state.category}}},
                authMode: "iam"
            });
            setGuides(resp.data.listGuidePosts.items.sort((a, b) => a.position - b.position));
        }
        void currentSession();
        void fetchData();
    }, [navigate, state?.category]);
    return (
        <section id="guide" className="content-section">
            <div className="content entire-paragraph">
                <h1 style={{textAlign: "center"}}>{state.category}</h1>
                <Grid container style={{marginTop: 25}}>
                    {
                        guides.map((guide) => (
                            <Grid item xs={12} lg={6} key={guide.id} style={{marginBottom: 50}}>
                                <Card className="card-post" sx={{maxWidth: 400}} orientation="vertical"
                                      size="md" variant="soft" style={{margin: "0 auto"}}>
                                    <CardHeader className="card-post" title={guide.title}
                                                subheader={dayjs.unix(guide.date).format('DD/MM/YYYY')}/>
                                    <CardContent className="card-post">
                                        <Typography variant="body1">
                                            {guide.short_description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className="card-post">
                                        <Button size="small" startIcon={<ReadMoreIcon/>} onClick={() =>
                                            navigate("/guide_detail", {state: {id: guide.id}})}>
                                            Read More
                                        </Button>
                                        {
                                            isAdmin &&
                                            <Button size="small" startIcon={<EditIcon/>} onClick={() =>
                                                navigate("/guideForm", {state: {id: guide.id}})}>
                                                Edit Guide
                                            </Button>
                                        }
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </section>
    );
}

export default GuideCategory;
