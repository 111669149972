/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGuidePost = /* GraphQL */ `
  mutation CreateGuidePost(
    $input: CreateGuidePostInput!
    $condition: ModelGuidePostConditionInput
  ) {
    createGuidePost(input: $input, condition: $condition) {
      id
      category
      position
      title
      date
      short_description
      long_description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGuidePost = /* GraphQL */ `
  mutation UpdateGuidePost(
    $input: UpdateGuidePostInput!
    $condition: ModelGuidePostConditionInput
  ) {
    updateGuidePost(input: $input, condition: $condition) {
      id
      category
      position
      title
      date
      short_description
      long_description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGuidePost = /* GraphQL */ `
  mutation DeleteGuidePost(
    $input: DeleteGuidePostInput!
    $condition: ModelGuidePostConditionInput
  ) {
    deleteGuidePost(input: $input, condition: $condition) {
      id
      category
      position
      title
      date
      short_description
      long_description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBlogPost = /* GraphQL */ `
  mutation CreateBlogPost(
    $input: CreateBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    createBlogPost(input: $input, condition: $condition) {
      id
      title
      date
      short_description
      image
      long_description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBlogPost = /* GraphQL */ `
  mutation UpdateBlogPost(
    $input: UpdateBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    updateBlogPost(input: $input, condition: $condition) {
      id
      title
      date
      short_description
      image
      long_description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBlogPost = /* GraphQL */ `
  mutation DeleteBlogPost(
    $input: DeleteBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    deleteBlogPost(input: $input, condition: $condition) {
      id
      title
      date
      short_description
      image
      long_description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDonationRecord = /* GraphQL */ `
  mutation CreateDonationRecord(
    $input: CreateDonationRecordInput!
    $condition: ModelDonationRecordConditionInput
  ) {
    createDonationRecord(input: $input, condition: $condition) {
      id
      name
      email
      device_model_name
      external_serial_number
      internal_serial_number
      device_condition
      device_status
      device_use
      comments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDonationRecord = /* GraphQL */ `
  mutation UpdateDonationRecord(
    $input: UpdateDonationRecordInput!
    $condition: ModelDonationRecordConditionInput
  ) {
    updateDonationRecord(input: $input, condition: $condition) {
      id
      name
      email
      device_model_name
      external_serial_number
      internal_serial_number
      device_condition
      device_status
      device_use
      comments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDonationRecord = /* GraphQL */ `
  mutation DeleteDonationRecord(
    $input: DeleteDonationRecordInput!
    $condition: ModelDonationRecordConditionInput
  ) {
    deleteDonationRecord(input: $input, condition: $condition) {
      id
      name
      email
      device_model_name
      external_serial_number
      internal_serial_number
      device_condition
      device_status
      device_use
      comments
      createdAt
      updatedAt
      __typename
    }
  }
`;
