import Stack from "@mui/material/Stack";
import React from "react";

function About() {
    return (
        <section id="about" className="content-section">
            <div className="content entire-paragraph">
                <h2>Who We Are</h2>
                <Stack direction={{xs: "column", lg: "row"}} spacing={{xs: 2, lg: 8}}
                       justifyContent="center" alignItems="center">
                    <img src="/atAmazonOfficeOne.jpeg" width={300} height={300} alt="At the AWS office"/>
                    <img src="/atAmazonOfficeThree.jpeg" width={300} height={300} alt="At the AWS office"/>
                    <img src="/atAmazonOfficeTwo.jpeg" width={300} height={300} alt="At the AWS office"/>
                </Stack>
                <p>
                    <br/>
                    <br/>
                    Yijoon Kim and Antonio Valles Miles founded Computers for Students in August of 2023, with the aim
                    of nurturing a new generation of individuals prepared for the digital world.
                    <br/>
                    <br/>
                    Central to our approach is the promotion of digital literacy. This initiative goes beyond technical
                    skills to encompass a deep understanding of how to effectively utilize and engage with digital
                    technologies. By imparting knowledge on topics such as internet safety, data privacy, and critical
                    thinking in the digital realm, we aim to equip students with the tools necessary to navigate and
                    thrive in the increasingly interconnected digital world.
                    <br/>
                    <br/>
                    Moreover, our commitment is to equip the next generation with the skills to diagnose and repair
                    electronic devices. It's not just about fixing gadgets; it's about addressing the urgent problem of
                    e-waste.
                    <br/>
                    <br/>
                    In today's fast-paced world, electronic waste is a pressing issue. Computers for Students hopes to
                    address this by empowering students with technical skills while also fostering a sense of
                    responsibility and environmental awareness.
                    <br/>
                    <br/>
                    We hope to conduct in-person teaching sessions, providing hands-on experiences. These students will
                    use their knowledge to independently maintain the electronic products we donate, thus reducing
                    environmental waste.
                </p>
            </div>
        </section>
    );
}

export default About;
