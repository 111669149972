import React from "react";

function Achievements() {
    return (
        <section id="achievements" className="content-section">
            <div className="content entire-paragraph">
                <h2>Our Achievements</h2>
                <p>Hello</p>
            </div>
        </section>
    );
}

export default Achievements;
