import breaks from "@bytemd/plugin-breaks";
import frontmatter from "@bytemd/plugin-frontmatter";
import gemoji from "@bytemd/plugin-gemoji";
import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import math from "@bytemd/plugin-math";
import mediumZoom from "@bytemd/plugin-medium-zoom";
import mermaid from "@bytemd/plugin-mermaid";
import {Viewer} from "@bytemd/react";
import {generateClient} from "aws-amplify/api";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getGuidePost} from "../graphql/queries";
import "bytemd/dist/index.css";

const plugins = [
    breaks(),
    frontmatter(),
    gemoji(),
    gfm(),
    highlight(),
    math(),
    mediumZoom(),
    mermaid()
];

function GuideDetail() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [guidePost, setGuidePost] = useState({});

    useEffect(() => {
        async function fetchData() {
            if (!state?.id) {
                navigate("/guide");
                return;
            }
            const client = generateClient();
            const guidePost = await client.graphql({
                query: getGuidePost,
                variables: {id: state.id},
                authMode: "iam"
            });
            setGuidePost(guidePost.data.getGuidePost);
        }

        void fetchData();
    }, [navigate, state.id]);

    return (
        <section id="about" className="content-section">
            <div className="content entire-paragraph">
                <Viewer plugins={plugins} value={guidePost?.long_description}/>
            </div>
        </section>
    );
}

export default GuideDetail;
