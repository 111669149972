import React from "react";

function Programs() {
    return (
        <section id="programs" className="content-section">
            <div className="content entire-paragraph">
                <h2>What We Do</h2>
                <br/>
                <p style={{textAlign: "center"}}>At Computers for Students, our mission is to nurture a new generation
                                                 of individuals prepared for the
                                                 digital world. Here's how we achieve our goal:</p>
                <br/>
                <h3 style={{textAlign: "left"}}>Promoting Digital Literacy</h3>
                <p>
                    Our focus is on empowering students with more than just technical skills.
                    We provide comprehensive education on effective utilization and engagement with digital
                    technologies.
                    Covering topics such as internet safety, data privacy, and critical thinking in the digital realm,
                    we equip students with the essential tools to navigate and excel in today's interconnected digital
                    landscape.
                </p>
                <br/> 
                <h3 style={{textAlign: "left"}}>Adressing E-Waste:</h3>
                <p>
                    In today's fast-paced world, electronic waste is a pressing issue.
                    Computers for Students is dedicated to addressing this challenge by recycling electronic devices hands-on and empowering students with technical skills. 
                    Through our initiatives, we aim to reduce environmental waste and promote sustainable practices.
                </p>
                <br/>
                <h3 style={{textAlign: "left"}}>Empowering Through Electronic Repair</h3>
                <p>
                    We're committed to equipping the next generation with the skills to diagnose and repair electronic
                    devices.
                    Our focus isn't just on fixing gadgets; it's about addressing the urgent problem of e-waste.
                    By teaching essential repair skills, we empower students to extend the lifespan of electronic
                    products we donate and contribute to environmental sustainability
                </p>
                <br/>
                <h3 style={{textAlign: "left"}}>Donation of Laptops and Desktops:</h3>
                <p>
                    Upon the conclusion of our teaching session, we will generously donate computers, accompanied
                    by the essential tools necessary for the maintenance of the technology. Additionally, we will
                    provide customized manuals for each computer, offering detailed instructions on assembly,
                    disassembly, and other information crucial for maintenance. Furthermore, our commitment extends to
                    donating spare parts for the computer, ensuring the students have the resources needed for
                    ongoing usability and support.
                </p>
                <br/>
                <h3 style={{textAlign: "left"}}>In-Person Teaching Sessions</h3>
                <p>
                    We will conduct in-person meeting sessions at a chosen location. During these sessions, we will
                    invite both ourselves and the selected students to participate.
                </p>
                <br/>
                <h3 style={{textAlign: "left"}}>Beneficiaries of our club:</h3>
                <p>
                    The schools, institutions, and communities we engage with will experience significant advantages through our education initiatives and the
                    donation of computers. This contribution translates into substantial cost savings, as
                    these educational institutions won't need to allocate their budgets for the purchase of computers
                    This, in turn, enables more funds to be redirected towards enhancing the quality of
                    student education. Moreover, these donated devices will serve as valuable educational tools,
                    enriching and expanding students' learning experiences.
                </p>
                <br/>
                <h3 style={{textAlign: "left"}}>Online Presence:</h3>
                <p>
                    Stay updated with our organization's activities and initiatives through our dedicated website,
                    where we showcase ongoing projects, share educational resources,
                    and highlight our commitment to sustainability and digital literacy.
                </p>
                <br/>
                <p style={{textAlign: "center"}}>
                    Join us in our journey towards nurturing a new generation of individuals prepared for the digital
                    world. Together, we can make a meaningful impact in the world of technology and beyond.
                </p>
            </div>
        </section>
    );
}

export default Programs;
